<!-- Localized -->
<template>
    <div class="w-full p-5 text-base-content-300">
        <div class="flex flex-col gap-2">
            <div v-if="isLoading" class="flex min-h-full min-w-full justify-center items-center rounded-md">
                <loader class="my-5" height="32px" width="32px" :loading="isLoading" />{{ $t('general.loading') }}</div>
            <div v-if="packageData && !isLoading" class="flex flex-col gap-2">
                <!-- <span class="text-base-content-300 text-md font-medium"> SLA (in Days)</span> -->
                <FormulateForm  class="min-h-full flex-1">
                    <div class="flex flex-col justify-between min-h-full">
                        <div class="form flex w-full justify-between flex-1">
                            <FormulateInput :disabled="true" class="w-1/5 auditbox" :label="$t('components.tenant.tenant_packages.created_by')" :placeholder="$t('components.tenant.tenant_packages.created_by')" type="text"  v-model="getPackage.created_by" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <FormulateInput :disabled="true" class="w-1/5 auditbox" :label="$t('components.tenant.tenant_packages.created_at')" :placeholder="$t('components.tenant.tenant_packages.created_at')" type="text"   :value="formattedCreatedAt" :element-class="(context, classes) => ['flex-1  min-w-full'].concat(classes)" />
                                    <FormulateInput :disabled="true" class="w-1/5 auditbox" :label="$t('components.tenant.tenant_packages.updated_by')" :placeholder="$t('components.tenant.tenant_packages.updated_by')" type="text"  v-model="packageData.modified_by" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                        <FormulateInput :disabled="true" class="w-1/5 auditbox" :label="$t('components.tenant.tenant_packages.updated_at')" :placeholder="$t('components.tenant.tenant_packages.updated_at')" type="text"  v-model="formattedupdatedAt" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <!-- <div class="card-actions justify-end" v-allow="'package.edit'">
                            <Button text="Update" type="primary" :disabled="this.$route.params.action == 'view'" @click="updateTat()"/>
                        </div> -->
                    </div>
                </FormulateForm>
            </div>
        </div>
    </div>
</template>

<script>
// const Button = () => import("@/components/button");
// import axios from "@/axios";
// import loader from "@shared/loader";
// import axios from "@/axios";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { checkPermission } from "@shared/utils/functions";
// import { DateTime } from "luxon";
import { FormatDateNew } from "@/plugins/functions"

export default {
    name: "audit",
    components: {
        // loader,
        // Button,
    },
    data: () => ({
        packageData: null,
        isLoading: false,
    }),
    async mounted() {
        await this.fetchScreeningData();
    },
    computed: {
        ...mapGetters(["getPackage"]),
        formattedCreatedAt() {
            // return DateTime.fromISO(this.packageData.created_at)
            // .setZone('UTC')
            // .toFormat('dd MMM yyyy');
            return FormatDateNew(new Date(this.packageData.created_at))
        },
        formattedupdatedAt() {
            // return DateTime.fromISO(this.packageData.modified_at)
            // .setZone('UTC')
            // .toFormat('dd MMM yyyy');
            return FormatDateNew(new Date(this.packageData.modified_at))
        },
    },

    methods: {
        FormatDateNew,
        checkPermission,
        ...mapActions(["fetchPackageData"]),
        ...mapMutations(["SET_PACKAGE"]),
        async fetchScreeningData() {
            this.isLoading = true;
            if (!this.getPackage) {
                await this.fetchPackageData(this.$route.params.packageName);
                this.packageData = this.getPackage;
                console.log('---->',this.packageData)
            }
            this.packageData = this.getPackage;
            this.isLoading = false;
        },
    },
};
</script>

<style >
.auditbox div div{
    background-color: #DDDDDD !important;
}

.auditbox div label{
    color: black;
}
</style>
